import React, {useState, useEffect} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Column, Columns} from 'bloomer';
import {ContObraComp} from '../../ContObra';
import {BtnReplay} from '../../BtnReplay';
import {GlobalStylePage, ContArrow, ContImgMoon, ImgMoon, ContImgRino, ImgRino} from './styles';
import {ArrowNavTop, ArrowNavRight, ArrowNavBottom, ArrowNavLeft, ArrowNavLeftCreditos} from '../../ArrowsNavigation';
import {GlobalStyle, ContImgLeft, ContImgRight} from '../../../styles/GlobalStyles';
import {gsap} from "gsap";
import {SpeechBubbleMini} from '../../SpeechBubbles/SpeechBubbleMini';
import {SpeechBubble} from '../../SpeechBubbles';
import imgMoon from '../../../images/obras/obra-1/luna.png';
import imgRino from '../../../images/obras/obra-1/rinoceronte.png';
import useSound from 'use-sound';
import soundRino from '../../../images/obras/obra-2/rinoceronte.mp3';
import soundMoon from '../../../images/obras/obra-1/moon.mp3';
import imgLeft from '../../../images/space.png';
import imgRight from '../../../images/obras/obra-2/obra.jpeg';
import {BtnParrilla} from '../../BtnParrilla';

const arNav = {
  top: null,
  right: 2,
  bottom: null,
  left: 'creditos',
};

const Page01Comp = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [initSpeechBubbleMini, setInitSpeechBubbleMini] = useState(false);
  const [initSpeechBubble, setInitSpeechBubble] = useState(false);
  const [initAnimatedMoon, setInitAnimatedMoon] = useState(false);
  const [initAnimatedRino, setInitAnimatedRino] = useState(false);
  const [initSoundMoon, setInitSoundMoon] = useState(false);
  const [initSoundRino, setInitSoundRino] = useState(false);
  const [reLaunchAll, setReLaunchAll] = useState(false);

  /*const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "obras/obra-1/obra.jpeg"}) {
        childImageSharp {
          fixed(width: 340, height: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);*/
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "obras/obra-1/obra.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 750, maxHeight: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const mouseOver = () => {
    setShowArrows(true);
  };
  const mouseLeaver = () => {
    setShowArrows(false);
  };

  const fnReLaunchAll = () => {
    fnReLaunchMoon(0);
    fnReLaunchRino(0);
    setReLaunchAll(!reLaunchAll);
  }

  const fnReLaunchMoon = (noReplay = 1) => {
    setInitAnimatedMoon(false);
    setInitSoundMoon(false);

    if(noReplay){
      setTimeout(function (){
        setInitAnimatedMoon(true);
      }, 1000);
    }
  };
  const fnReLaunchRino = (noReplay = 1) => {
    setInitAnimatedRino(false);
    setInitSoundRino(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedRino(true);
      }, 1000);
    }
  };

  const fnAnimateMoon = () => {
    return (
      <ContImgMoon className={'img-animated'} onClick={() => fnReLaunchMoon()}>
        {
          initAnimatedMoon &&
          <ImgMoon
            id={'animate-moon'}
            className={'animate-moon'}
            src={imgMoon}
            alt="Luna"
          />
        }
      </ContImgMoon>
    )
  }
  const fnAnimateRino = () => {
    return (
      <ContImgRino className={'img-animated'} onClick={() => fnReLaunchRino()}>
        {
          initAnimatedRino &&
          <ImgRino
            id={'animate-rino'}
            className={'animate-rino'}
            src={imgRino}
            alt="Rinoceronte"
          />
        }
      </ContImgRino>
    )
  }

  const fnSpeechBubbleMini = () => {
    return (
      <>
        {
          initSpeechBubbleMini &&
          <SpeechBubbleMini
            id={'speech-bubble-mini'}
            className={'speech-bubble-mini'}
            onClick={() => setInitSpeechBubble(true)}
          />
        }
      </>
    )
  }
  const fnSpeechBubble = () => {
    return (
      <>
        {
          initSpeechBubble &&
          <SpeechBubble
            id={'speech-bubble'}
            className={''}
            classClose={'close-top-left'}
            onClick={() => setInitSpeechBubble(false)}
          >
            <div>Ahora el seto se blanquea durante una hora con flor</div>
            <div>transitoria de nieve, una flor más repentina,</div>
            <div>que la del verano, ni brota ni se marchita.</div>
          </SpeechBubble>
        }
      </>
    )
  }

  const fnLeft = () => {
    return <ContImgLeft src={imgLeft} />
  }
  const fnRight = () => {
    return <ContImgRight src={imgRight} />
  }

  const [playMoon, { stopMoon }] = useSound(
    soundMoon,
    { volume: 0.1 }
  );
  const [playRino, { stopRino }] = useSound(
    soundRino,
    { volume: 0.3 }
  );

  useEffect(() => {
    setTimeout(function (){
      setInitAnimatedMoon(true);
    }, 3000);

    setTimeout(function (){
      setInitAnimatedRino(true);
    }, 6000);

    setTimeout(function (){
      setInitSpeechBubbleMini(true);
    }, 9000);
  },[reLaunchAll]);

  useEffect(() => {
    const windowWrap = gsap.utils.wrap(0, window.innerWidth);
    const winWidth = window.innerWidth;
    let arPosRino = {};

    if(winWidth < 767){
      arPosRino = {x: 120}
    } else if(winWidth < 1023){
      arPosRino = {x: 120}
    } else if(winWidth < 1215){
      arPosRino = {x: 120}
    } else if(winWidth < 1407){
      arPosRino = {x: 800}
    } else if(winWidth < 1919){
      arPosRino = {x: 820}
    } else {
      arPosRino = {x: 950}
    }

    if(initAnimatedMoon){
      gsap.to(".animate-moon", {
        x: 100,
        modifiers: {
          x: x => windowWrap(parseFloat(x)) + "px"
        },
      });

      if(!initSoundMoon){
        playMoon();
        setInitSoundMoon(true);
      }
    }

    if(initAnimatedRino){
      gsap.to(".animate-rino", {
        x: arPosRino.x,
        opacity: 1,
        duration: 3,
        delay: 0,
      });
      gsap.to(".animate-rino", {
        x: arPosRino.x,
        opacity: 0,
        duration: 3,
        delay: 3,
      });
      if(!initSoundRino){
        playRino();
        setInitSoundRino(true);
      }
    }

  },[initAnimatedMoon, initAnimatedRino]);

  return (
    <ContObraComp>
      <BtnParrilla/>
      <BtnReplay onClick={() => fnReLaunchAll()}/>
      <div className={'columns-padding-no cont-obra-height'} onMouseOver={mouseOver} onMouseLeave={mouseLeaver}>
        <GlobalStyle/>
        <GlobalStylePage/>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavTop toPage={arNav.top}/>}
            </ContArrow>
          </Column>
        </Columns>

        <Columns className="columns-responsive columns-obra-height flex-center" isMultiline={false} isCentered={true}>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && arNav.left === 'creditos' ? <ArrowNavLeftCreditos toPage={arNav.left}/> : <ArrowNavLeft toPage={arNav.left}/>}
            </ContArrow>
          </Column>

          <Column isSize={{mobile: 10, tablet: 10, desktop: 10, widescreen: 10}} className={'flex-center container-animated'}>
            {/*<Img fixed={data.file.childImageSharp.fixed} />*/}
            <Img className={'img-obra'} fluid={data.placeholderImage.childImageSharp.fluid}/>
            {fnAnimateMoon()}
            {fnAnimateRino()}
            {fnSpeechBubbleMini()}
            {fnSpeechBubble()}
            {fnLeft()}
            {fnRight()}
          </Column>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavRight toPage={arNav.right}/>}
            </ContArrow>
          </Column>

        </Columns>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavBottom toPage={arNav.bottom}/>}
            </ContArrow>
          </Column>
        </Columns>
      </div>
    </ContObraComp>
  );
};

export default Page01Comp;
