import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStylePage = createGlobalStyle`
  @media(min-width: 300px) and (max-width: 767px){
    .img-obra{
      width: 100%;
      height: auto;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .img-obra{
      width: 570px;
      height: 100%;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .img-obra{
      width: 780px;
      height: 100%;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .img-obra{
      /* width: 354px;
      height: 566px; */
      width: 400px;
      height: 115%;
    }
    .speech-bubble-mini{
      margin-top: -135px;
      margin-left: -282px;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .img-obra{
      width: 620px;
      height: 976px;
    }
    .speech-bubble-mini{
      margin-top: -135px;
      margin-left: -380px;
    }
  }
  @media(min-width: 1920px){
    .img-obra{
      width: 720px;
      height: 1150px;
    }
    .speech-bubble-mini{
      margin-top: -135px;
      margin-left: -440px;
    }
  }
`
export const ContArrow = styled.div`
  width: 100%;
  height: 40px;
  z-index: 999;
`
export const ContImgMoon = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    position: absolute;
    width: 200px;
    height: 200px;
    margin-left: -101px;
    margin-top: -305px;
    z-index: 99;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    position: absolute;
    width: 350px;
    height: 350px;
    margin-left: -95px;
    margin-top: -323px;
    z-index: 99;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    position: absolute;
    width: 400px;
    height: 400px;
    margin-left: -95px;
    margin-top: -436px;
    z-index: 99;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    position: absolute;
    width: 200px;
    height: 200px;
    margin-left: -95px;
    margin-top: -222px;
    z-index: 99;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    position: absolute;
    width: 300px;
    height: 300px;
    margin-left: -95px;
    margin-top: -322px;
    z-index: 99;
  }
  @media(min-width: 1920px){
    position: absolute;
    width: 400px;
    height: 400px;
    margin-left: -100px;
    margin-top: -422px;
    z-index: 99;
  }
`
export const ImgMoon = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgRino = styled.div`
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 160px;
    height: auto;
    margin-top: -220px;
    margin-left: -180px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 220px;
    height: auto;
    margin-top: -200px;
    margin-left: -290px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 300px;
    height: auto;
    margin-top: -270px;
    margin-left: -370px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 330px;
    height: auto;
    margin-top: 160px;
    margin-left: -430px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 440px;
    height: auto;
    margin-top: 110px;
    margin-left: -420px;
  }
  @media(min-width: 1920px){
    width: 550px;
    height: auto;
    margin-top: 110px;
    margin-left: -550px;
  }
`
export const ImgRino = styled.img`
  width: 100%;
  height: 100%;
`
